import DateInput from '@rsa-digital/evo-shared-components/components/Form/DateInput';
import TextInput from '@rsa-digital/evo-shared-components/components/Form/TextInput';
import { addLeadingZerosToDateValue } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import useValidation from '@rsa-digital/evo-shared-components/helpers/forms/useValidation';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import FormFooter from 'components/FormFooter';
import NameInput from 'components/NameInput';
import QuestionField from 'components/QuestionField';
import useAddressQuestions from 'forms/AboutYouForm/AddressForm/questions';
import useAboutYouQuestions from 'forms/AboutYouForm/questions';
import {
  PageTitle,
  trackFieldError,
  trackFormTextFieldFocus,
  trackTextButtonClick,
} from 'helpers/eventTracking';
import { scrollAndTrackError } from 'helpers/forms';
import { INPUT_REGEX_ALPHABETICAL_REPLACE, INPUT_REGEX_NAME } from 'helpers/inputRegexes';
import { useRetrieveQuoteDetails } from 'state/formData/retrieveQuote';
import { PostcodeQuestionField } from './styles';
import useRetrieveLatestQuoteRules from './validation';

type CsRetrieveQuoteFormContent = {
  csPetRetrieveYourLatestQuote: {
    submit_cta_text: string;
  };
};

export const query = graphql`
  query {
    csPetRetrieveYourLatestQuote {
      submit_cta_text
    }
  }
`;

const RetrieveLatestQuoteForm: React.FC<{
  moveNext: () => void;
}> = ({ moveNext }) => {
  const { submit_cta_text } = useStaticQuery<CsRetrieveQuoteFormContent>(
    query
  ).csPetRetrieveYourLatestQuote;
  const [retrieveQuoteDetails, updateRetrieveQuoteDetails] = useRetrieveQuoteDetails();
  const aboutYouQuestions = useAboutYouQuestions();
  const postcodeQuestion = useAddressQuestions().manual.postcode;

  const formErrorRules = useRetrieveLatestQuoteRules();

  const { getError, showValidation, validateOnSubmit } = useValidation(
    retrieveQuoteDetails,
    formErrorRules,
    trackFieldError
  );

  return (
    <form onSubmit={validateOnSubmit(moveNext, scrollAndTrackError)}>
      <QuestionField
        question={aboutYouQuestions.customerFirstName}
        errorText={getError('customerFirstName')}>
        <NameInput
          id="customerFirstName"
          placeholder={aboutYouQuestions.customerFirstName.placeholder}
          maxLength={40}
          value={retrieveQuoteDetails.customerFirstName}
          onChange={(e) => {
            if (e.target.value.match(INPUT_REGEX_NAME)) {
              updateRetrieveQuoteDetails({
                customerFirstName: e.target.value,
              });
            }
          }}
          onBlur={() => {
            showValidation('customerFirstName');
          }}
          onFocus={trackFormTextFieldFocus('First name')}
        />
      </QuestionField>
      <QuestionField
        question={aboutYouQuestions.customerLastName}
        errorText={getError('customerLastName')}>
        <NameInput
          id="customerLastName"
          placeholder={aboutYouQuestions.customerLastName.placeholder}
          maxLength={30}
          value={retrieveQuoteDetails.customerLastName}
          onChange={(e) => {
            if (e.target.value.match(INPUT_REGEX_NAME)) {
              updateRetrieveQuoteDetails({
                customerLastName: e.target.value,
              });
            }
          }}
          onBlur={() => {
            showValidation('customerLastName');
          }}
          onFocus={trackFormTextFieldFocus('Last name')}
        />
      </QuestionField>
      <QuestionField
        question={aboutYouQuestions.customerDob}
        errorText={getError('customerDob')}>
        <DateInput
          id="customerDob"
          value={retrieveQuoteDetails.customerDob}
          onChange={(e) =>
            updateRetrieveQuoteDetails({
              customerDob: e,
            })
          }
          onBlur={() => {
            updateRetrieveQuoteDetails({
              customerDob: addLeadingZerosToDateValue(retrieveQuoteDetails.customerDob),
            });
            showValidation('customerDob');
          }}
          onFocus={trackFormTextFieldFocus('Date of birth')}
        />
      </QuestionField>
      <PostcodeQuestionField
        question={postcodeQuestion}
        errorText={getError('customerPostcode')}>
        <TextInput
          id="customerPostcode"
          placeholder={postcodeQuestion.placeholder}
          value={retrieveQuoteDetails.customerPostcode}
          onChange={(e) => {
            updateRetrieveQuoteDetails({
              customerPostcode: e.target.value
                .replace(INPUT_REGEX_ALPHABETICAL_REPLACE, '')
                .substring(0, 8)
                .toUpperCase()
                .trim(),
            });
          }}
          onBlur={() => {
            showValidation('customerPostcode');
          }}
          onFocus={trackFormTextFieldFocus('Postcode')}
        />
      </PostcodeQuestionField>
      <FormFooter
        contentColumns={{ desktop: 8 }}
        moveNextButton={{
          text: submit_cta_text,
          onClick: () => trackTextButtonClick(PageTitle.RetrieveQuote, submit_cta_text),
        }}
        pageTitle={PageTitle.RetrieveQuote}
      />
    </form>
  );
};

export default RetrieveLatestQuoteForm;
