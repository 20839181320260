import FieldGrid from '@rsa-digital/evo-shared-components/components/Form/Field/FieldGrid';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import LoadingOverlay from '@rsa-digital/evo-shared-components/components/LoadingOverlay';
import { scrollToElement } from '@rsa-digital/evo-shared-components/helpers/scroll';
import useRetrieveQuote from 'apiHelpers/quote/useRetrieveQuote';
import { AxiosError } from 'axios';
import { isQuoteNotFoundError } from 'businessLogic/errors/isQuoteNotFoundError';
import { graphql } from 'gatsby';
import React, { useState } from 'react';
import Layout from 'components/Layout';
import RichTextWithModal from 'components/RichTextWithModal';
import { SessionExpiryOption } from 'components/SessionExpiry/SessionExpiryWrapper';
import RetrieveLatestQuoteForm from 'forms/RetrieveLatestQuoteForm';
import { isAxiosError } from 'helpers/axiosResponseHelpers';
import useDefaultErrorHandling from 'helpers/errorHandling';
import { PageTitle, trackAPIError } from 'helpers/eventTracking';
import { usePageTracking } from 'helpers/pageTracking';
import useLoadingState from 'helpers/useLoadingState';
import { ErrorPanelWithMargin, StyledRichTextWithModal } from './styles';

type RetrieveQuoteProps = {
  data: {
    csPetRetrieveYourLatestQuote: {
      meta_title: string;
      hero: {
        heading: string;
        subheading: string;
      };
      submit_cta_text: string;
      intro_text: string;
      error_messages: {
        not_found: string;
      };
    };
  };
};

export const query = graphql`
  query {
    csPetRetrieveYourLatestQuote {
      meta_title
      hero {
        heading
        subheading
      }
      submit_cta_text
      intro_text
      error_messages {
        not_found
      }
    }
  }
`;

export const retrieveQuoteErrorPanelId = 'retrieve-quote-error-panel';

const RetrieveQuote: React.FC<RetrieveQuoteProps> = ({
  data: {
    csPetRetrieveYourLatestQuote: {
      meta_title,
      hero: { heading, subheading },
      intro_text,
      error_messages,
    },
  },
}) => {
  usePageTracking(meta_title);
  const { retrieveLatestQuoteAndNavigate } = useRetrieveQuote();
  const { isLoading, withLoadingState } = useLoadingState();
  const [errorPanelText, setErrorPanelText] = useState<string>('');
  const errorHandler = useDefaultErrorHandling();

  return (
    <Layout
      pageTitle={PageTitle.RetrieveQuote}
      heading={heading}
      subheading={subheading}
      metaTitle={meta_title}
      headingGridItemProps={{ desktop: 8, tabletLandscape: 8, tabletPortrait: 6 }}
      subheadingGridItemProps={{ desktop: 8, tabletLandscape: 8, tabletPortrait: 6 }}
      sessionExpiryOption={SessionExpiryOption.NO_SESSION_EXPIRY}>
      <Grid>
        <GridItem desktop={8} tabletLandscape={8} tabletPortrait={6}>
          {isLoading && <LoadingOverlay loadingMessage="Retrieving quote, please wait" />}
          <StyledRichTextWithModal
            pageTitle={PageTitle.RetrieveQuote}
            html={intro_text}
          />
          {errorPanelText && (
            <FieldGrid alignLeft>
              <ErrorPanelWithMargin id={retrieveQuoteErrorPanelId}>
                <RichTextWithModal
                  pageTitle={PageTitle.RetrieveQuote}
                  aria-live="assertive"
                  html={errorPanelText}
                />
              </ErrorPanelWithMargin>
            </FieldGrid>
          )}
          <RetrieveLatestQuoteForm
            moveNext={async () => {
              try {
                setErrorPanelText('');
                await withLoadingState(retrieveLatestQuoteAndNavigate);
              } catch (err) {
                const error: AxiosError = err as AxiosError;
                if (isAxiosError(error) && isQuoteNotFoundError(error)) {
                  trackAPIError(error);
                  setErrorPanelText(error_messages.not_found);
                  scrollToElement(retrieveQuoteErrorPanelId, 20);
                } else {
                  errorHandler(error);
                }
              }
            }}
          />
        </GridItem>
      </Grid>
    </Layout>
  );
};

export default RetrieveQuote;
