import { addYearsToDate } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import {
  dateValueLessThanOrEqualTo,
  dateValueValid,
  dateValueValidDay,
  dateValueValidMonth,
  dateValueValidYear,
  lengthGreaterOrEqualTo,
  matchesRegex,
  required,
  requiredDateValue,
} from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import { ValidationRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import { POSTCODE_REGEX } from 'helpers/regexes';
import { RetrieveQuoteDetails } from 'state/formData/retrieveQuote';
import { CsErrorsMissingOnly } from 'types/contentStack';

type CsRetrieveLatestQuoteErrorMessages = {
  csPetAboutYouQuestionsV2: {
    first_name: CsErrorsMissingOnly;
    last_name: CsErrorsMissingOnly;
    date_of_birth: {
      error_messages: {
        missing: string;
        date_in_future: string;
        too_young: string;
        invalid_day: string;
        invalid_month: string;
        invalid_date: string;
        year_too_short: string;
      };
    };
  };
  csPetAboutYouManualAddress: {
    postcode: {
      error_messages: {
        missing: string;
        too_short: string;
        invalid_format: string;
      };
    };
  };
};

const query = graphql`
  query {
    csPetAboutYouQuestionsV2 {
      first_name {
        error_messages {
          missing
        }
      }
      last_name {
        error_messages {
          missing
        }
      }
      date_of_birth {
        error_messages {
          missing
          date_in_future
          too_young
          invalid_day
          invalid_month
          invalid_date
          year_too_short
        }
      }
    }
    csPetAboutYouManualAddress {
      postcode {
        error_messages {
          missing
          too_short
          invalid_format
        }
      }
    }
  }
`;

const useRetrieveLatestQuoteRules = (): ValidationRules<RetrieveQuoteDetails> => {
  const errorMessages = useStaticQuery<CsRetrieveLatestQuoteErrorMessages>(query);

  return {
    customerFirstName: [
      required(errorMessages.csPetAboutYouQuestionsV2.first_name.error_messages.missing),
    ],
    customerLastName: [
      required(errorMessages.csPetAboutYouQuestionsV2.last_name.error_messages.missing),
    ],
    customerDob: [
      requiredDateValue(
        errorMessages.csPetAboutYouQuestionsV2.date_of_birth.error_messages.missing
      ),
      dateValueValidDay(
        errorMessages.csPetAboutYouQuestionsV2.date_of_birth.error_messages.invalid_day
      ),
      dateValueValidMonth(
        errorMessages.csPetAboutYouQuestionsV2.date_of_birth.error_messages.invalid_month
      ),
      dateValueValid(
        errorMessages.csPetAboutYouQuestionsV2.date_of_birth.error_messages.invalid_date
      ),
      dateValueValidYear(
        errorMessages.csPetAboutYouQuestionsV2.date_of_birth.error_messages.year_too_short
      ),
      dateValueLessThanOrEqualTo(
        new Date(),
        errorMessages.csPetAboutYouQuestionsV2.date_of_birth.error_messages.date_in_future
      ),
      dateValueLessThanOrEqualTo(
        addYearsToDate(new Date(), -18),
        errorMessages.csPetAboutYouQuestionsV2.date_of_birth.error_messages.too_young
      ),
    ],
    customerPostcode: [
      required(errorMessages.csPetAboutYouManualAddress.postcode.error_messages.missing),
      lengthGreaterOrEqualTo(
        5,
        errorMessages.csPetAboutYouManualAddress.postcode.error_messages.too_short
      ),
      matchesRegex(
        POSTCODE_REGEX,
        errorMessages.csPetAboutYouManualAddress.postcode.error_messages.invalid_format
      ),
    ],
  };
};

export default useRetrieveLatestQuoteRules;
